header {
    background: url("../images/header.svg") no-repeat center center;
    height: 530px;
    background-size: cover;
}
@media (max-width: 700px) {
    header {
        height: 300px;
    }
}
header .CompanyLogo h4 {
    width: 100%;
    padding: 22px 38px;
    color: white;
    font-size: 20px;
    font-weight: 700;
    cursor: default;
}
@media (max-width: 700px) {
    header .CompanyLogo h4 {
        font-size: 16px;
        padding: 20px;
    }
}
header .headerWrap {
    width: 100%;
    height: 80%;
    flex-direction: column;
    gap: 32px;
}
@media (max-width: 700px) {
    header .headerWrap {
        gap: 12px;
        height: 60%;
    }
}
header .headerWrap .logo {
    width: 48px;
    height: 48px;
}
@media (max-width: 700px) {
    header .headerWrap .logo {
        width: 32px;
        height: 32px;
    }
}
header .headerWrap .logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

header .headerWrap .title p {
    font-size: 24px;
    font-weight: 500;
    color: white;
    line-height: normal;
}
@media (max-width: 700px) {
    header .headerWrap .title p {
        font-size: 20px;
        margin-bottom: 8px;
    }
}
header .headerWrap .title h1 {
    font-size: 44px;
    font-weight: 700;
    line-height: 56px;
    text-align: center;
    color: white;
}
@media (max-width: 700px) {
    header .headerWrap .title h1 {
        font-size: 32px;
        line-height: normal;
    }
}
header .headerWrap .TitleBtn {
    width: 240px;
    height: 56px;
}
@media (max-width: 700px) {
    header .headerWrap .TitleBtn {
        height: 48px;
    }
}
header .headerWrap .TitleBtn button {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background-color: #51baa4;
    color: white;
    font-size: 20px;
    font-weight: 700;
}
@media (max-width: 700px) {
    header .headerWrap .TitleBtn button {
        font-size: 16px;
    }
}

main {
}
main .mainWrap {
    width: 940px;
    /* background-color: white; */
}
.Main1 {
    background: #263031;
    padding-bottom: 8px;
}

.Main2 {
    background-color: #eee;
    padding: 40px;
}
@media (max-width: 700px) {
    .Main2 {
        padding: 40px 20px;
    }
}
.Main3 {
    background-color: #fff;
    padding: 80px;
}
@media (max-width: 700px) {
    .Main3 {
        padding: 20px;
    }
}
.Main4 {
    background: url("../images/MainbackImg.jpeg") no-repeat center center;
    height: 500px;
    background-size: cover;
    padding: 0 40px;
    flex-direction: column;
}
@media (max-width: 700px) {
    .Main4 {
        background: url("../images/MainBackImgS.png") no-repeat center center;
        height: 300px;
        padding: 0 20px;
        background-size: cover;
    }
}

main .mainWrap .mainTop {
    padding: 40px;
}
@media (max-width: 700px) {
    main .mainWrap .mainTop {
        padding: 20px;
    }
}

main .mainWrap .mainTop .mainChooseWrap {
    width: 100%;
}
main .mainWrap .mainTop .mainChooseWrap .mainChoose {
    width: 50%;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    color: #435759;
    padding: 10px;
    transition: color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
    border-bottom: 2px solid #435759;
}
main .mainWrap .mainTop .mainChooseWrap .mainChoose.active {
    color: #51baa4;
    border-bottom: 2px solid #4de7ac;
}

main .mainWrap .mainTop .subChooseWrapper {
    margin-top: 40px;
    flex-direction: column;
    gap: 20px;
    display: flex;
}
main .mainWrap .mainTop .subChooseWrapper .subTitle {
    color: white;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}
@media (max-width: 700px) {
    main .mainWrap .mainTop .subChooseWrapper .subTitle {
        font-size: 16px;
    }
}
main .mainWrap .mainTop .subChooseWrapper .SubChooseWrap {
    display: flex;
    justify-content: center;
    width: 100%;
}
main .mainWrap .mainTop .subChooseWrapper .SubChooseWrap .subChoose {
    display: flex;
    justify-content: center;
    width: 100%;
}
main
    .mainWrap
    .mainTop
    .subChooseWrapper
    .SubChooseWrap
    .subChoose
    .hiddenRadio {
    display: none;
}
main .mainWrap .mainTop .subChooseWrapper .SubChooseWrap .subChoose label {
    width: 100%;
    height: 100%;
    border: 1px solid #4de7ac;
    color: #4de7ac;
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
    border-radius: 4px;
    transition: color 0.3s ease, border-color 0.3s ease;
    cursor: pointer;
}
@media (max-width: 700px) {
    main .mainWrap .mainTop .subChooseWrapper .SubChooseWrap .subChoose label {
        padding: 15px 0px;
        font-size: 14px;
    }
}
main
    .mainWrap
    .mainTop
    .subChooseWrapper
    .SubChooseWrap
    .subChoose
    .hiddenRadio:checked
    + .label {
    background: #4de7ac;
    color: #263031;
}
main .mainWrap .mainTop .subChooseWrapper .SubChooseWrap .SubChooseSelect {
    width: 100%;
}
main
    .mainWrap
    .mainTop
    .subChooseWrapper
    .SubChooseWrap
    .SubChooseSelect
    select {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../images/selectdown.svg") no-repeat center right 12px;
    border: 1px solid #4de7ac;
    border-radius: 4px;
    padding: 10px;
    color: #4de7ac;
    text-align: center;
    font-weight: 700;
}
@media (max-width: 700px) {
    main
        .mainWrap
        .mainTop
        .subChooseWrapper
        .SubChooseWrap
        .SubChooseSelect
        select {
        padding: 15px 15px 15px 0px;
        font-size: 14px;
        background: url("../images/selectdown.svg") no-repeat center right 5px;
    }
}
main .mainWrap .mainTop .subChooseWrapper .leftWrap {
    width: 66%;
}
main .mainWrap .mainTop .subChooseWrapper .rightWrap {
    width: 33%;
}
main .mainWrap .mainBottom {
    padding: 40px;
    background-color: #4de7ac;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
@media (max-width: 700px) {
    main .mainWrap .mainBottom {
        padding: 20px;
    }
}
main .mainWrap .mainBottom h4 {
    color: #777;
    font-size: 20px;
    font-weight: 700;
}
@media (max-width: 700px) {
    main .mainWrap .mainBottom h4 {
        font-size: 16px;
    }
}
main .mainWrap .mainBottom .radioBtnWrap {
    gap: 80px;
}
@media (max-width: 700px) {
    main .mainWrap .mainBottom .radioBtnWrap {
        justify-content: space-around;
        width: 100%;
        gap: 0px;
    }
}
main .mainWrap .mainBottom .radioBtnWrap .radioBtn {
    flex-direction: column;
    gap: 12px;
    font-size: 16px;
    color: #777;
}
@media (max-width: 700px) {
    main .mainWrap .mainBottom .radioBtnWrap .radioBtn {
        font-size: 14px;
    }
}
main .mainWrap .mainBottom .radioBtnWrap .radioBtn .priceRadio {
    display: none;
}
main .mainWrap .mainBottom .radioBtnWrap .radioBtn .priceRadio + label {
    background: url("../images/radioBtn.svg") no-repeat center left;
    width: 24px;
    height: 24px;
    cursor: pointer;
}
main .mainWrap .mainBottom .radioBtnWrap .radioBtn .priceRadio:checked + label {
    background: url("../images/radiBtnChk.svg") no-repeat center left;
}
main .mainWrap .mainBottom .ReqConsultWrap {
    width: 100%;
    border-top: 1px solid #fff;
}
main .mainWrap .mainBottom .ReqConsultWrap .consultTitle {
    margin-top: 20px;
    font-size: 16px;
    color: #777;
    font-weight: 500;
    line-height: normal;
}
main .mainWrap .mainBottom .ReqConsultWrap .ReqConsult {
    display: flex;
    width: 100%;
    gap: 40px;
    margin-top: 12px;
}
@media (max-width: 700px) {
    main .mainWrap .mainBottom .ReqConsultWrap .ReqConsult {
        gap: 12px;
        flex-direction: column;
    }
}
main .mainWrap .mainBottom .ReqConsultWrap .ReqConsult .ReqConsultInput {
    display: flex;
    width: 100%;
    align-items: center;
}
main .mainWrap .mainBottom .ReqConsultWrap .ReqConsult .ReqConsultInput p {
    margin-right: 12px;
}
main .mainWrap .mainBottom .ReqConsultWrap .ReqConsult .ReqConsultInput input {
    width: 100%;
    padding: 5px 10px 0px !important;
    border-radius: 5px;
    border: 1px solid #ddd;
    height: 40px;
    gap: 4px;
    background-color: #fff;
}
main
    .mainWrap
    .mainBottom
    .ReqConsultWrap
    .ReqConsult
    .ReqConsultInput
    .react-datepicker-wrapper {
    width: 100%;
}
main .mainWrap .mainBottom .PrivacyChkWrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
main .mainWrap .mainBottom .PrivacyChkWrap p {
    font-size: 16px;
    color: #777;
}
main .mainWrap .mainBottom .PrivacyChkWrap .PrivacyChk input {
    display: none;
}
main .mainWrap .mainBottom .PrivacyChkWrap .PrivacyChk input + label {
    align-items: center;
    color: #777;
    padding-left: 28px;
    line-height: 20px;

    font-size: 16px;
    font-weight: 500;
    background: url("../images/checkbox_off.svg") no-repeat center left;
    background-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
}
@media (max-width: 700px) {
    main .mainWrap .mainBottom .PrivacyChkWrap .PrivacyChk input + label {
        font-size: 14px;
    }
}
main .mainWrap .mainBottom .PrivacyChkWrap .PrivacyChk input:checked + label {
    background: url("../images/checkbox_on.svg") no-repeat center left;
    background-size: 20px;
}
main .mainWrap .mainBottom .ReqConsultBtn {
    width: 100%;
}
main .mainWrap .mainBottom .ReqConsultBtn button {
    width: 100%;
    display: flex;
    height: 80px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 12px;
    background: #263031;
    color: white;
    font-size: 20px;
    font-weight: 700;
}

@media (max-width: 700px) {
    main .mainWrap .mainBottom .ReqConsultBtn button {
        height: 60px;
    }
}
.Main2 .mainWrap .bannerWrap {
    display: flex;
    gap: 20px;
    flex-direction: column;
}
.Main2 .mainWrap .bannerWrap p {
    color: #777;
    font-size: 16px;
}
.Main2 .mainWrap .bannerWrap .banner {
    width: 100%;
    height: auto;
}
.Main2 .mainWrap .bannerWrap .banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Main3 .mainWrap {
    flex-direction: column;
}
.Main3 .mainWrap h1 {
    color: #25383b;

    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
}
@media (min-width: 701px) {
    .desktop-header {
        display: block;
    }
    .mobile-header {
        display: none;
    }
}

@media (max-width: 700px) {
    .desktop-header {
        display: none;
    }
    .mobile-header {
        display: block;
    }
}
@media (max-width: 700px) {
    .Main3 .mainWrap h1 {
        font-size: 24px;

        line-height: normal;
        margin-bottom: 8px;
        word-break: keep-all;
    }
    .responsive-header {
        white-space: pre-line;
    }
}
.Main3 .mainWrap p {
    color: #25383b;

    text-align: center;

    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
@media (max-width: 700px) {
    .Main3 .mainWrap p {
        font-size: 16px;
    }
}
.Main3 .mainWrap .imgWrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 24px 0;
    width: 100%;
}
.Main3 .mainWrap .imgWrap .img {
    width: 100%;
}
.Main3 .mainWrap .imgWrap .img img {
    width: 100%;
}
@media (max-width: 700px) {
    .Main3 .mainWrap .imgWrap {
        grid-template-columns: repeat(2, 1fr);
    }
}
.Main3 .mainWrap .Main3BtnWrap {
    width: 100%;
}
.Main3 .mainWrap .Main3BtnWrap button {
    display: flex;
    width: 300px;
    color: #51baa4;
    padding: 10px;

    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 4px solid #51baa4;
    font-size: 20px;
    font-weight: 700;
}
@media (max-width: 700px) {
    .Main3 .mainWrap .Main3BtnWrap button {
        border: 2px solid #51baa4;
        font-size: 16px;
    }
}
.Main4 .Main4Wrap {
    padding-bottom: 24px;
    border-bottom: 1px solid #ccc;
    width: 100%;
    text-align: center;
}
@media (max-width: 700px) {
    .Main4 .Main4Wrap {
        border-bottom: 2px solid #ccc;
    }
}
.Main4 .Main4Wrap p {
    color: #fff;

    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
@media (max-width: 700px) {
    .Main4 .Main4Wrap p {
        font-size: 20px;
    }
}
.Main4 h1 {
    color: #fff;

    text-align: center;

    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 24px 0;
}
@media (max-width: 700px) {
    .Main4 h1 {
        font-size: 32px;
    }
}
.Main4 .Main4BtnWrap {
}
.Main4 .Main4BtnWrap button {
    display: flex;
    width: 300px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 20px;
    font-weight: 700;

    background-color: transparent;
}

footer {
    display: flex;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    background-color: #1e2832;
}
@media (max-width: 700px) {
    footer {
        padding: 20px;
    }
}

footer .footerWrap {
    width: 100%;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid #5c5d5e;
}
footer .footerWrap p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #5c5d5e;
}
@media (max-width: 700px) {
    footer .footerWrap p {
        font-size: 14px;
    }
}
footer h1 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #fff;
}
.ComfirmWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}
.ConfirmBoxWrapperTitle {
    flex-direction: column;
    gap: 8px;
}
.ConfirmBoxWrapperTitle h1 {
    color: #07090c;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.ConfirmBoxWrapperTitle p {
    color: #07090c;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.personalInfoWrap {
    display: flex;
    padding: 20px;
    flex-direction: column;
}
.personalInfoHeaderWrap {
}
.personalInfoHeaderWrap h1 {
    color: #07090c;
    font-size: 24px;
    font-style: normal;
    margin: 20px 0px 40px 0px;
    line-height: normal;
}
.personalInfoHeaderWrap p {
    color: #07090c;
    font-size: 18px;
    font-style: normal;
    margin: 20px 0;
    line-height: normal;
}
