@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 100;
    src: url(../fonts/NotoSansKR-Thin.woff) format("woff");
}
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/NotoSansKR-Light.woff) format("woff");
}
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/NotoSansKR-Regular.woff) format("woff");
}
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    src: url(../fonts/NotoSansKR-Medium.woff) format("woff");
}
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/NotoSansKR-Bold.woff) format("woff");
}
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 900;
    src: url(../fonts/NotoSansKR-Black.woff) format("woff");
}

@font-face {
    font-family: "NanumSquare_ac";
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/NanumSquare_acL.woff) format("woff");
}
@font-face {
    font-family: "NanumSquare_ac";
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/NanumSquare_acR.woff) format("woff");
}
@font-face {
    font-family: "NanumSquare_ac";
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/NanumSquare_acB.woff) format("woff");
}
@font-face {
    font-family: "NanumSquare_ac";
    font-style: normal;
    font-weight: 800;
    src: url(../fonts/NanumSquare_acEB.woff) format("woff");
}
@font-face {
    font-family: "Prentendard";
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/Pretendard-Light.otf) format("woff");
}
@font-face {
    font-family: "Prentendard";
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/Pretendard-Regular.otf) format("woff");
}

@font-face {
    font-family: "Prentendard";
    font-style: normal;
    font-weight: 500;
    src: url(../fonts/Pretendard-Medium.otf) format("woff");
}
@font-face {
    font-family: "Prentendard";
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/Pretendard-Bold.otf) format("woff");
}
@font-face {
    font-family: "Prentendard";
    font-style: normal;
    font-weight: 900;
    src: url(../fonts/Pretendard-Black.otf) format("woff");
}
@font-face {
    font-family: "SpoqaHanSansNeo";
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/SpoqaHanSansNeo-Bold.otf) format("woff");
}
@font-face {
    font-family: "SpoqaHanSansNeo";
    font-style: normal;
    font-weight: 500;
    src: url(../fonts/SpoqaHanSansNeo-Medium.otf) format("woff");
}
@font-face {
    font-family: "SpoqaHanSansNeo";
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/SpoqaHanSansNeo-Regular.otf) format("woff");
}
