* {
    font-family: SpoqaHanSansNeo, sans-serif !important;
}

.FlexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ColorWhite {
    color: white;
    line-height: normal;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
    right: 0;
    bottom: 4px !important  ;
}

.popup {
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 3000000;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(7px);
    top: 0;
    left: 0;
    padding-bottom: 50px;
    overflow: auto;
    padding-top: 100px;
}
.popup.show {
    display: flex;
    animation: popup-show 0.3s;
}
.popup_container_component {
    width: 100%;
    background-color: #fff;
    padding-bottom: 30px;
    position: relative;

    border-radius: 8px;

    animation: popup-container-show 0.3s;
    overflow: hidden;
}
.exit_btn {
    background: url("../images/exit.svg") no-repeat center center;
    background-size: 24px;
    width: 28px;
    height: 28px;
}
.popup .popup_container_component .popup_header {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #eee;
}
.popup .popup_container_component .popup_wrap {
    width: 100%;
}
.popup .popup_container_component .popup_body {
    width: 940px;
    flex-direction: column;
    gap: 40px;
}
@media (max-width: 700px) {
    .popup .popup_container_component .popup_body {
        gap: 0px;
    }
}
.popup .popup_container_component .popup_body .popup_body_header {
    padding: 40px;
    flex-direction: column;
}
@media (max-width: 700px) {
    .popup .popup_container_component .popup_body .popup_body_header {
        padding: 20px 40px;
        justify-content: flex-start;
    }
}
.popup .popup_container_component .popup_body .popup_body_header h1 {
    color: #07090c;
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
}
@media (max-width: 700px) {
    .popup .popup_container_component .popup_body .popup_body_header h1 {
        text-align: start;
        font-size: 24px;
        line-height: normal;
    }
}
.popup .popup_container_component .popup_body .popup_body_header p {
    color: #07090c;
    text-align: center;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
}
@media (max-width: 700px) {
    .popup .popup_container_component .popup_body .popup_body_header p {
        margin-top: 8px;
        text-align: start;
        font-size: 16px;
        line-height: normal;
    }
}
.popup .popup_container_component .popup_body .popup_body_content {
    width: 100%;
}
.popup
    .popup_container_component
    .popup_body
    .popup_body_content
    .popup_body_img_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px;
}
.popup
    .popup_container_component
    .popup_body
    .popup_body_content
    .popup_body_img_wrap
    img {
    width: 200px;
    object-fit: cover;
}
@media (max-width: 700px) {
    .popup
        .popup_container_component
        .popup_body
        .popup_body_content
        .popup_body_img_wrap
        img {
        width: 120px;
    }
}
.popup
    .popup_container_component
    .popup_body
    .popup_body_content
    .popup_body_content_thing {
    padding: 40px;
    border: 1px solid #eee;
}
.popup
    .popup_container_component
    .popup_body
    .popup_body_content
    .popup_body_content_thing
    h1 {
    color: #07090c;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    margin-bottom: 20px;
}
@media (max-width: 700px) {
    .popup
        .popup_container_component
        .popup_body
        .popup_body_content
        .popup_body_content_thing
        h1 {
        font-size: 24px;
        line-height: normal;
    }
}
.popup
    .popup_container_component
    .popup_body
    .popup_body_content
    .popup_body_content_thing
    p {
    color: #07090c;

    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}
@media (max-width: 700px) {
    .popup
        .popup_container_component
        .popup_body
        .popup_body_content
        .popup_body_content_thing
        p {
        font-size: 16px;
        line-height: normal;
    }
}
.ConfirmBoxWrapper {
    padding: 40px;
    gap: 60px;

    flex-direction: column;
}

.ConfirmBoxWrapper .ConfirmBoxWrapperBox {
    padding: 40px;
    background-color: #51baa4;
    width: 500px;
}
@media (max-width: 700px) {
    .ConfirmBoxWrapper .ConfirmBoxWrapperBox {
        width: 350px;
    }
}
.ConfirmBoxWrapper .ConfirmBoxWrapperBox .ConfirmBoxWrapperBoxTitle {
    margin-bottom: 20px;
}

.ConfirmBoxWrapper .ConfirmBoxWrapperBox .ConfirmBoxWrapperBoxTitle h1 {
    color: #fff;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

@media (max-width: 700px) {
    .ConfirmBoxWrapper .ConfirmBoxWrapperBox .ConfirmBoxWrapperBoxTitle h1 {
        font-size: 20px;
    }
}

.ConfirmBoxWrapper
    .ConfirmBoxWrapperBox
    .ConfirmBoxWrapperBoxContent
    .ConfirmBoxWrapperBoxContentItem {
    justify-content: start;
}

.ConfirmBoxWrapper
    .ConfirmBoxWrapperBox
    .ConfirmBoxWrapperBoxContent
    .numberCircle {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-color: #fff;
    color: #51baa4;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 12px;
}

.ConfirmBoxWrapper
    .ConfirmBoxWrapperBox
    .ConfirmBoxWrapperBoxContent
    .ConfirmBoxWrapperBoxContentItemText {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ConfirmBoxWrapper
    .ConfirmBoxWrapperBox
    .ConfirmBoxWrapperBoxContent
    .ConfirmBoxWrapperBoxContentIArrow {
    width: 24px;
    height: 24px;
    background: url("../images/DownWhiteArrow.svg") no-repeat center center;
    margin: 16px 10px;
}
.AccordionWrap {
    width: 100%;
}
.AccordionWrap .AccordionTitle {
    margin-bottom: 20px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.accordion-section {
    width: 100%;
}
.accordion-section .accordion-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    cursor: pointer;
}
.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    border-bottom: 1px solid #eee;
}
.accordion-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.accordion-text p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
.accordion-text ol {
    margin: 0px;
    padding-left: 20px;
    font-size: 14px;
}
.accordion-text ul {
    padding-left: 20px;
    list-style: disc;
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
}
.accordion-text li {
    font-size: 14px;
    list-style: auto;
    margin-bottom: 10px;
}
.accordion-content.active {
    padding-bottom: 20px;
}
.ConfirmBoxLink {
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.ConfirmBoxLink h1 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.ConfirmBoxLink .ConfirmBoxLinkBtnWrap {
    gap: 40px;
}
.ConfirmBoxLink .ConfirmBoxLinkBtnWrap .ConfirmBoxLinkBtn {
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
}
.ConfirmBoxLinkInputBox {
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: center;
}
.ConfirmBoxLinkInputBox input {
    padding: 0px 12px;
    border-radius: 4px;
    border: 1px solid #07090c;
    background: #fff;
    font-size: 14px;
    width: 200px;
}
.ConfirmBoxLinkInputBox button {
    padding: 0px 10px;
    border-radius: 4px;
    border: 1px solid #07090c;
    background: #eee;
}
.ConfirmBoxLinkLastBtn {
    border-radius: 100px;
    background: #51baa4;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: white;
    padding: 20px;
    width: 260px;
}
