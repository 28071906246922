* {
    box-sizing: border-box;
    word-break: break-word;
    -webkit-tap-highlight-color: transparent;
}

*,
html,
body {
    font-family: "Noto Sans KR", sans-serif !important;
    -webkit-text-size-adjust: none;
}
html,
body {
    width: 100%;
    height: 100%;
    margin: 0px;
}

main {
    min-height: 100%;
    padding-top: var(--header-height);
    position: relative;
}

html,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
img {
    margin: 0;
    padding: 0;
    border: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    font-size: 15px;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
#hd ul,
nav ul,
#ft ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
legend {
    position: absolute;
    margin: 0;
    padding: 0;
    font-size: 0;
    line-height: 0;
    text-indent: -9999em;
    overflow: hidden;
}
label,
input,
button,
select,
img {
    vertical-align: middle;
}
input,
button {
    margin: 0;
    padding: 0;
    font-size: 1em;
    outline: none;
}
button {
    cursor: pointer;
    outline: none;
    border: none;
    background: #fff;
}
textarea,
select {
    font-size: 1em;
    outline: none;
}
select {
    margin: 0;
}
p {
    margin: 0;
    padding: 0;
    word-break: keep-all;
}
hr {
    display: none;
}
pre {
    overflow-x: scroll;
    font-size: 1.1em;
}

a:link,
a:visited {
    text-decoration: none;
}
a:hover,
a:focus,
a:active {
    text-decoration: none;
}
ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
li {
    padding: 0;
    margin: 0;
    list-style: none;
}
